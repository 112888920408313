import { Flex, Image, Link } from '@chakra-ui/react';

import logo from '../../assets/images/logo.svg';
import { useThemeProvider } from '../../ThemeProvider';

const TenantLogo = ({ h = '20px' }) => {
  const { tenant } = useThemeProvider();
  console.log(tenant);
  return (
    <Flex
      order={{ base: '1', lgr: 'initial' }}
      alignSelf="stretch"
      align="center"
      direction="row"
      justifyContent="center"
      bg={tenant.logos.default ? 'white' : 'initial'}
      css={{
        '@media (min-width: 1200px)': {
          'clip-path': `polygon(0 0, 100% 0, 82% 100%, 0 100%);`,
        },
      }}
      flexBasis={`calc(14.4 * ${h})`}
      flexGrow={1}>
      <Link to="/dashboard">
        {tenant.logos.default ? (
          <Image src={tenant.logos.default} alt={`logo ${tenant.name}`} mr="8px" mb={{ lgr: '8px' }} h={h} />
        ) : (
          <Image src={logo} alt="logo Vendemmia" mr="8px" minH={'25px'} maxH={{ base: '25px', lgr: '29px' }} />
        )}
      </Link>
    </Flex>
  );
};

export default TenantLogo;
