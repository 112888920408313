import { cloneElement, useState, useLayoutEffect, useContext, useMemo, useCallback, Suspense, createContext } from 'react';

import { lighten, desaturate } from 'polished';
import styled from 'styled-components';

import { ChakraProvider, extendTheme, useTheme } from '@chakra-ui/react';

import { themeDefault } from './styles/theme';
import { tenant as tenantEnv } from './utils/tenant/theme';

const themeContext = extendTheme(themeDefault, { colors: tenantEnv.colors });

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeContext);
  const [tenant, setTenant] = useState(tenantEnv);

  const changeColors = useCallback((colors) => {
    setTheme((current) => extendTheme(current, { colors }));
  }, []);

  const changeLogo = useCallback((customTenant) => {
    setTenant((current) => ({ ...current, ...customTenant }));
  }, []);

  const value = useMemo(() => ({ changeLogo, changeColors, theme, tenant }), [theme, changeColors, changeLogo]);

  return (
    <ChakraProvider theme={theme}>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </ChakraProvider>
  );
};

export const useThemeProvider = () => useContext(ThemeContext);

const StyledDiv = styled.div`
  svg {
    ${({ styledcss }) => styledcss}
  }
`;

export const useSVGTheme = (Svg, custom = {}) => {
  const { colors } = useTheme();
  const fill = custom.primaryColor || colors.primary;
  const color = custom.secundaryColor || lighten(0.3, fill);

  return (
    <Suspense>
      {custom.styledCss ? (
        <StyledDiv styledcss={custom.styledCss}>
          <Svg />
        </StyledDiv>
      ) : (
        <Svg fill={fill} color={color} />
      )}
    </Suspense>
  );
};
