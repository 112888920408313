import React from 'react';

import { Box, Grid, GridItem, Link, Text, Image, Flex } from '@chakra-ui/react';

import vendemmiaAnalyticsLogo from '../../assets/svgs/vendemmia_analytics.svg';
import { useThemeProvider } from '../../ThemeProvider';
import TenantLogo from '../Logo/TenantLogo';

import FooterLinks from './FooterLinks';

const Footer = () => {
  const { theme, tenant } = useThemeProvider();
  const isTenant = tenant.logos.default;
  return isTenant ? (
    <Grid
      as="footer"
      gap="20px"
      bgColor={theme.colors.footer}
      templateColumns={{ base: '1fr', lgr: '288px 1fr 288px' }}
      templateRows={{ base: '87px 2fr 1fr', lgr: '87px 1fr 40px' }}
      templateAreas={{ base: `'logo' 'analytics' 'links'`, lgr: `'logo . analytics' '. . analytics' 'links links links'` }}
      h={{ lgr: '222px' }}
      w="full"
      pb="12px">
      <GridItem display="flex" flexGrow="1" gridArea="logo" alignContent={'stretch'} justifyContent="stretch">
        <TenantLogo h={'28px'} />
      </GridItem>
      <FooterLinks />
      <GridItem
        gridArea="analytics"
        alignContent={{ base: 'center', lgr: 'flex-end' }}
        justifyItems={{ base: 'center', lgr: 'flex-end' }}
        pr={{ lgr: '40px' }}>
        <Image src={vendemmiaAnalyticsLogo} />
      </GridItem>
    </Grid>
  ) : (
    <Grid
      as="footer"
      bgColor={theme.colors.footer}
      templateColumns="1fr"
      templateRows={{ base: '1fr 1fr', lgr: '1fr 60px' }}
      templateAreas={`'analytics' 'links'`}
      p={'20px'}
      w="full">
      <GridItem gridArea="analytics" alignContent="center" justifyItems={{ base: 'center', lgr: 'flex-start' }}>
        <Image src={vendemmiaAnalyticsLogo} />
      </GridItem>
      <FooterLinks />
    </Grid>
  );
};

export default Footer;
