import React from 'react';

import { MdHelp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Grid, Image } from '@chakra-ui/react';

import { useThemeProvider } from '../../ThemeProvider';
import DropdownProfile from '../DropdownProfile/DropdownProfile';
import TenantLogo from '../Logo/TenantLogo';
import FAQ from '../Modal/FAQ/FAQ';

import { HeaderStyles } from './HeaderStyles';
import { Navbar } from './Navbar';

const Header = () => {
  const { tenant } = useThemeProvider();
  return (
    <Box as="header" position="fixed" top="0" zIndex="1399" left="0" right="0" height="63px">
      <HeaderStyles />
      <Box display="flex" w="full" h="full" bgColor="primary" p={{ base: '0 20px', lgr: '0 25px 0 0' }} boxSizing="border-box">
        <Grid
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          w="full"
          gridTemplateColumns={{ base: '85px 163px 85px', lgr: '208px 6fr 1fr' }}>
          <TenantLogo />
          <Navbar />
          <Flex
            order={{ base: '2', lgr: 'initial' }}
            direction="row"
            align="center"
            gap={{ base: '5px', lgr: '20px' }}
            justify="flex-end">
            {/*<MdCircleNotifications size={25} color="#FFFFFFB3" cursor="pointer" onClick={() => {}} />*/}
            <FAQ selectedContent={0} customButton={<MdHelp size={25} color="#FFFFFFB3" cursor="pointer" />} />
            <DropdownProfile />
          </Flex>
        </Grid>
      </Box>
    </Box>
  );
};

export default Header;
