import React from 'react';

import { GridItem, Link, Text } from '@chakra-ui/react';

import FAQ from '../Modal/FAQ/FAQ';

function FooterLinks() {
  return (
    <GridItem
      display="flex"
      alignItems="center"
      justifyContent={'center'}
      gridArea="links"
      left="0"
      bottom="0"
      align="center"
      justify="center"
      color="white"
      fontSize="16px"
      fontWeight="semibold"
      flexWrap={{ base: 'wrap', lgr: 'nowrap' }}
      gap={{ base: '5px 14px', md: '14px' }}>
      <Text>Vendemmia {new Date().getFullYear()}</Text>
      {'|'}

      <Link href="https://vendemmia.com.br/" target="_blank">
        Visite nosso site
      </Link>
      {'|'}

      <FAQ selectedContent={0} color="white" size="16px" />
      {'|'}

      <FAQ selectedContent={1} color="white" size="16px" />
      {'|'}

      <FAQ selectedContent={2} color="white" size="16px" />
    </GridItem>
  );
}

export default FooterLinks;
