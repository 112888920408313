import React, { useState } from 'react';

import { MdClose } from 'react-icons/md';

import {
  Button,
  Divider,
  Flex,
  Grid,
  IconButton,
  Image,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import logo_vendemmia from '../../../assets/images/vendemmia-logo.png';
import { useThemeProvider } from '../../../ThemeProvider';

import '../../../styles/globals.css';
import HelpAnalyticsPanel from './components/HelpAnalyticsPanel';
import HelpInfoVendemmia from './components/HelpInfoVendemmia';
import HelpPrivacyPolicy from './components/HelpPrivacyPolicy';
import HelpTermUse from './components/HelpTermUse';

const FAQ = ({ selectedContent, customButton, showSupportRequest = true, showInfoVendemmia = true, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedTab, setSelectedTab] = useState(selectedContent);

  const { theme } = useThemeProvider();

  const options = [
    {
      name: 'Ajuda',
      title: 'Ajuda',
      content: <HelpAnalyticsPanel />,
      show: true,
    },
    {
      name: 'Termos de Uso',
      title: 'Termos de Uso',
      content: <HelpTermUse />,
      show: true,
    },
    {
      name: 'Políticas de privacidade',
      title: 'Políticas de privacidade',
      content: <HelpPrivacyPolicy />,
      show: true,
    },
    {
      name: 'Vendemmia',
      title: 'Vendemmia',
      content: <HelpInfoVendemmia />,
      show: showInfoVendemmia,
    },
  ];

  const handleTabClick = (index) => {
    if (options[index].name === 'Solicitar suporte') {
      window.open(options[index].link, '_blank');
    } else {
      setSelectedTab(index);
    }
  };

  return (
    <>
      <Button variant="link" onClick={onOpen} {...props}>
        {customButton ? customButton : <Text textColor="white"> {options[selectedContent]?.title} </Text>}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent h={{ base: '90vh', lg: '80vh' }} w={{ base: '90vw', lg: '60vw' }} maxW="none">
          <Grid templateColumns="30% 70%" h="90px">
            <Flex h="90px" bgColor="primary" align="center" justify="center">
              <Image src={logo_vendemmia} w={{ base: '100px', lg: '150px' }} />
            </Flex>

            <Flex bgColor="background" h="100%" justify="space-between" w="full" p={{ base: '10px', lg: '30px' }}>
              <Flex h="100%" align="center">
                <Text textAlign="center" textStyle="tableTitle" color="primary" fontWeight="bold">
                  {options[selectedTab]?.title}
                </Text>
              </Flex>

              <IconButton bgColor="transparent" left="2px" icon={<MdClose size={20} color="#E74C3C" />} onClick={onClose} />
            </Flex>
          </Grid>

          <Divider />

          <Tabs display="grid" gridTemplateColumns="30% 70%" h="full" onChange={handleTabClick} defaultIndex={selectedContent}>
            <TabList bgColor="primary" display="flex" flexDirection="column" border="none">
              {options.map(
                (button, index) =>
                  button.show && (
                    <Tab
                      key={index}
                      h="65px"
                      justifyContent="start"
                      textAlign="left"
                      textColor="white"
                      _selected={{ bgColor: theme.colors.linkColor }}>
                      {button.name}
                    </Tab>
                  )
              )}

              {showSupportRequest && (
                <Link
                  href="https://form.asana.com/?k=kO34GIkM2wrgrf_PHgHtKA&d=1205795695053953"
                  target="_blank"
                  h="65px"
                  p="8px 16px"
                  display="flex"
                  alignItems="center"
                  textColor="white"
                  _hover={{ bgColor: theme.colors.linkColor }}>
                  Solicitar suporte
                </Link>
              )}
            </TabList>

            <TabPanels bgColor="background" overflow="auto">
              {options.map((option, index) => (
                <TabPanel key={index} maxH={{ base: '75vh', lg: '655px' }} p="0px" display={selectedTab === index ? 'block' : 'none'}>
                  {option.content}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FAQ;
