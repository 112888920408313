import React, { useEffect, useRef, useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Flex, Image, Menu, MenuButton, MenuList, Text, useDisclosure, useTheme } from '@chakra-ui/react';

import { headerLinks } from './HeaderLinks';

export const NavbarContent = ({ isMobile }) => {
  const dropdownRef = useRef(null);
  const [activeIcon, setActiveIcon] = useState(null);
  const { onToggle, isOpen, onClose } = useDisclosure();
  const { colors } = useTheme();

  const location = useLocation();
  const navigate = useNavigate();

  const isActualPageActive = (item) => {
    if (item.link && item.link.length > 0 && location.pathname === item.link) {
      return true;
    }

    return item.subMenu && item.subMenu.length > 0 && item.subMenu.some((element) => location.pathname.includes(element?.link));
  };

  const toggleIcon = (item) => {
    setActiveIcon(activeIcon === item ? null : item);
    onToggle();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveIcon(null);
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Flex
      w="full"
      align="center"
      gap={{ base: '20px', lgr: '3%', xl: '28px' }}
      alignItems="center"
      justify={{ lg: 'center' }}
      direction={{ base: 'column', lgr: 'row' }}>
      {headerLinks.map((item, key) => (
        <React.Fragment key={key}>
          <Menu direction="column">
            <MenuButton
              ref={dropdownRef}
              variant="link"
              fontWeight="bold"
              w={{ base: '100%', lgr: 'initial' }}
              textAlign={{ base: 'left', lg: 'initial' }}
              _hover={{ textDecoration: 'none' }}
              onClick={() => {
                if (item.link && item.link.length > 0) {
                  navigate(item.link);
                } else {
                  toggleIcon(item.title);
                }
              }}>
              <Flex align="center" justify="space-between" gap={{ lgr: '5px' }}>
                <Text
                  whiteSpace="nowrap"
                  textStyle="tableTitle"
                  color={{ base: colors.primary, lgr: isActualPageActive(item) ? colors.navActive : colors.nav }}
                  fontWeight={isActualPageActive(item) ? '700' : '500'}
                  fontSize="20px">
                  {item.title}
                </Text>

                {item.subMenu && item.subMenu.length > 0 ? (
                  isOpen && activeIcon === item.title ? (
                    <MdKeyboardArrowUp color={isMobile ? colors.primary : colors.nav} />
                  ) : (
                    <MdKeyboardArrowDown color={isMobile ? colors.primary : colors.nav} />
                  )
                ) : (
                  <></>
                )}
              </Flex>
            </MenuButton>

            {item.subMenu && item.subMenu.length > 0 && (
              <MenuList
                p="18px"
                w="327px"
                border="1px"
                borderRadius="7px"
                borderColor="#A885F982"
                shadow="2xl"
                zIndex="999"
                maxH="70vh"
                overflowY="auto">
                <Flex gap="14px" direction="column">
                  <Text fontSize="15px" color="primary" textStyle="paragraph">
                    {item?.subTitle}
                  </Text>

                  {item.subMenu.map((sub, index) => (
                    <Link to={sub.link} w="full" key={index} _hover={{ textDecoration: 'none' }}>
                      <Flex gap="20px" align="center">
                        <Image src={sub.icon} w="47px" h="41px" />
                        <Flex direction="column">
                          <Text
                            textStyle="tableTitle"
                            fontSize={{ base: '16px', lg: '16px', xl: '20px' }}
                            fontWeight="bold"
                            color="primary"
                            _hover={{ textDecoration: 'underline' }}>
                            {sub.title}
                          </Text>
                          <Text textStyle="paragraph" fontSize="12px" color={colors.primary}>
                            {sub.subTitle}
                          </Text>
                        </Flex>
                      </Flex>
                    </Link>
                  ))}
                </Flex>
              </MenuList>
            )}
          </Menu>
        </React.Fragment>
      ))}
    </Flex>
  );
};
